import React from "react";
import { Helmet } from "react-helmet";

export default function Search(){
    return (
        <>
            <Helmet>
                <title>404 Page Not Found</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div className="container vh-100">
                <div className="row justify-content-center centered">
                    <div className="col-12 text-center">
                        <h1 style={{ fontSize: '50px' }}>404 Page Not Found</h1>
                    </div>
                </div>
            </div>
            <br />
        </>
    )
}