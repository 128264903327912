import React from 'react'
import './Index.css';
import _cia from '../images/_cia.jpg';
import { Helmet } from "react-helmet";
import LoadingBar from 'react-top-loading-bar'

export default function Index() {
    const d = new Date();
    let year = d.getFullYear().toString();

    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    window.history.pushState({}, null, '/');

    return (
        <>
        <LoadingBar color='#001d3b' progress={100} height={5} />
            <Helmet>
                <title>How to Become a Cia Agent - How to become a CIA Agent - {year}</title>
                <meta name='description' content='Introduction What Do CIA Agents Do? Types of CIA Agents CIA Agent Salaries and Job Growth Steps to becoming a CIA Agent CIA Certifications CIA Career Advancement Should You Become a CIA Agent? Going Away Tip INTRODUCTION The Central Intelligence Agency (CIA) is a huge body of professionals from multiple fields and distinct skills sets... Read More' />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div style={{ backgroundColor: "#E6ECF0" }}>
                <div className="container-fluid" style={{backgroundColor: '#000', color: 'white'}}>
                    <div className="row justify-content-center text-center" style={{ padding: "2%", fontSize: "28px" }}>
                        HOW TO BECOME A CIA AGENT
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#001d3b" data-header-text-color="light" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '765px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=001d3b&amp;header_text_color=light&amp;href=https%3A%2F%2Fwww.howtobecomeaciaagent.com%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <ul className="left-sec pl-0 sticky-top" style={{ listStyleType: 'none', width: '100%' }}>
                                <li><a href="#intro" onClick={(e) => removeDefault(e)}>Introduction</a></li>
                                <li><a href="#what-do-cia-agent" onClick={(e) => removeDefault(e)}>What Do CIA Agents Do?</a></li>
                                <li><a href="#types-of-cia-agent" onClick={(e) => removeDefault(e)}>Types of CIA Agents</a></li>
                                <li><a href="#cia-agent-salaries" onClick={(e) => removeDefault(e)}>CIA Agent Salaries and Job Growth</a></li>
                                <li><a href="#steps-to-become-cia-agent" onClick={(e) => removeDefault(e)}>Steps to becoming a CIA Agent</a></li>
                                <li><a href="#cia-certification" onClick={(e) => removeDefault(e)}>CIA Certifications</a></li>
                                <li><a href="#cia-career" onClick={(e) => removeDefault(e)}>CIA Career Advancement</a></li>
                                <li><a href="#should-you-become-cia-agent" onClick={(e) => removeDefault(e)}>Should You Become a CIA Agent?</a></li>
                                <li><a href="#going-away" onClick={(e) => removeDefault(e)}>Going Away Tip</a></li>
                                <li><a href="#sugges" onClick={(e) => removeDefault(e)}>Suggestions</a></li>
                            </ul>
                        </div>
                        <div className="col-md-8">
                            <div className="row ml-0 mr-0">
                                <h2 id="intro" className="sec-heading">INTRODUCTION</h2>
                            </div>
                            <p>
                                The Central Intelligence Agency (CIA) is a huge body of professionals from multiple fields and distinct skills sets that are utilized to preserve the U.S. nation’s interest, policies, and national security issues in the global perspectives. The CIA covertly collects and analyses foreign intelligence to design her policies on national security threats, foreign governments and industries, technological research, and terrorist cells operative within and outside global war zones.
                            </p>
                            <p>
                                Working as an independent federal agency, the CIA passes information to the U.S. heads of state, the President and his cabinet to help them decide on issues related to the foreign policy and strategic military decisions to take preempt measures preserving the U.S. national security interests. The CIA conducts its missions under the U.S. President’s direction. The CIA works on foreign collection by using advanced technologies to ensure smooth performance in her mission areas of information collection, analysis, covert operations, and counterintelligence.
                            </p>
                            <p>
                                The CIA hires only the most qualified, immaculate and competent candidates with no issues with law as her agents. The hiring is through rigorous standards. The CIA employees could be scientists, computer experts, data analysts, economists, linguists, mathematicians, accountants, medical experts and the like.
                            </p>
                            <div className="container">
                                <div className="row" style={{ border: '1px solid #001d3b', borderRadius: '10px', backgroundColor: '#ccbd92' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}>HOW TO BECOME A CIA AGENT</h5>
                                    </div>
                                    <div className="col-md-5 pt-2 pb-2 pr-2 pl-2">
                                        <img src={_cia} style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row ml-0 mr-0">
                                <h2 id="what-do-cia-agent" className="sec-heading">What Do CIA Agents Do?</h2>
                            </div>
                            <p>Here is a list of some of services that CIA agents do:</p>
                            <ul className="content-listing pl-0" style={{ listStyleType: 'none' }}>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Provide insights on national threats</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Gather sensitive information and intelligence</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Critically analyze and communicate information to concerned authorities</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Conduct covert operations within and outside the U.S.</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Cyber security Surveillance</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Provide logistics</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Medical services</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Work with graphics/multimedia/photography/publications</li>
                            </ul>
                            <div className="row ml-0 mr-0">
                                <h2 id="types-of-cia-agent" className="sec-heading">Types of CIA Agents</h2>
                            </div>
                            <p>Although the mainstream media propagates CIA employing only secret agents, but based on the candidate’s education and skills areas, there are a lot of other positions available with the CIA.</p>
                            <p>Here is a list of different categories of CIA agents:</p>
                            <ul className="content-listing pl-0" style={{ listStyleType: 'none' }}>
                                <li><i className="fa fa-check" aria-hidden="true"></i> NCS Language Officer</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Specialized Skills – Targeting Officer</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Computer Engineer</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Computer Forensics Engineer</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Network Engineer</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Program Management Engineer</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Economic Analyst</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Political Analyst</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Clinical Psychologist</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Research Psychologist</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Medical Officer</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Intelligence Collection Analyst</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Professional Trainee</li>
                            </ul>
                            <div className="row ml-0 mr-0">
                                <h2 id="cia-agent-salaries" className="sec-heading">CIA Agent Salaries and Job Growth</h2>
                            </div>
                            <p>According to cia.gov, (Aug. 2015), the annual salary range for CIA special agents was $74,872 – $136, 1771. As this is a tough field, the growth is lower than the average but employment opportunities would continue increasing. The CIA also hires lawyers as “Attorney (Honors and Lateral)” and pays them $68,712 – $136,771 annually. Likewise the “Political Analyst” positions will get paid between $50,861 – 98,305. The salary figures can vary according to the location of service, particular skills set, education and experience. You may also refer to the GS scale for Federal Law Enforcement positions to determine the compensation.</p>
                            <table className="table text-center table-bordered">
                                <tbody>
                                    <tr style={{backgroundColor: 'rgb(5 50 96)', color: 'white'}}>
                                        <th style={{fontWeight: 'normal'}}>Legal Occupations</th>
                                        <th style={{fontWeight: 'normal'}}>CIA Agents</th>
                                        <th style={{fontWeight: 'normal'}}>Total, all Occupations</th>
                                    </tr>
                                    <tr style={{fontSize: '13px'}}>
                                        <td>$80,080</td>
                                        <td>$119,250</td>
                                        <td>$37,690</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>If you are interested in knowing different career categories and departments of the CIA, the following list would help.</p>
                            <ul className="content-listing pl-0" style={{ listStyleType: 'none' }}>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Analysis</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Clandestine Service</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Facilities Service</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Legal services</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Medical Services</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Logistics Services</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Security</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Multimedia and Publications</li>
                            </ul>
                            <p>The CIA offers multiple careers in accordance with your education, skills, and experience. According to the BLS (2017), the number of jobs in CIA was 807,000, with a median pay of 62,960 per year.  So, you may get a slow but a rewarding career.</p>
                            <table className="table text-center table-bordered">
                                <tbody>
                                    <tr style={{backgroundColor: 'rgb(5 50 96)', color: 'white'}}>
                                        <th style={{fontWeight: 'normal'}}>Total, all Occupations</th>
                                        <th style={{fontWeight: 'normal'}}>Investigative Occupations</th>
                                        <th style={{fontWeight: 'normal'}}>CIA Agents</th>
                                    </tr>
                                    <tr style={{fontSize: '13px'}}>
                                        <td>7%</td>
                                        <td>11%</td>
                                        <td>8%</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table text-center table-bordered table-responsive-md">
                                <tbody>
                                    <tr style={{backgroundColor: 'rgb(5 50 96)', color: 'white'}}>
                                        <th style={{fontWeight: 'normal'}}>Occupational Title</th>
                                        <th style={{fontWeight: 'normal'}}>Employment, 2016</th>
                                        <th style={{fontWeight: 'normal'}}>Projected Employment, 2026</th>
                                        <th style={{fontWeight: 'normal'}}>Percent</th>
                                        <th style={{fontWeight: 'normal'}}>Numeric</th>
                                    </tr>
                                    <tr style={{fontSize: '13px'}}>
                                        <td>CIA Agents</td>
                                        <td>807,000</td>
                                        <td>857,500</td>
                                        <td>8</td>
                                        <td>65,000</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="row ml-0 mr-0">
                                <h2 id="steps-to-become-cia-agent" className="sec-heading">Steps to becoming a CIA Agent</h2>
                            </div>
                            <div className="container-fluid p-0" id="steps">
                                <div className="row">
                                    <div className="col-2">
                                        <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                    </div>
                                    <div className="col-9 ml-2">
                                        <h3 className="gdlr-process-title">Step 1: Academic Requirement</h3>
                                        <p className="text-justify">The CIA position offer requires you to have a bachelor’s degree or higher. There is no direct academic path to follow or a degree program directly offered by them. Different fields require different expertise in majors like foreign studies, international affairs, political science, medical, economics, statistics, finance, computer sciences and more. Expertise in a foreign language and exposure to foreign culture is highly valued.</p>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-2">
                                        <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                    </div>
                                    <div className="col-9 ml-2">
                                        <h3 className="gdlr-process-title">Step 2: Professional Experience</h3>
                                        <p className="text-justify">Experience in the military security, or law enforcement would help but is not mandatory. Having a grasp on foreign language with experience of living abroad and having foreign culture understanding is desirable. For Clandestine Service, the CIA does not make their job description public. The CIA has her own education and training frameworks to train her personnel for various positions.</p>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-2">
                                        <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                    </div>
                                    <div className="col-9 ml-2">
                                        <h3 className="gdlr-process-title">Step 3: Acquiring CIA Training</h3>
                                        <p className="text-justify">The CIA recruits and trains the right candidates in a number of fields and branches. The entry requirements may vary but you’ll be evaluated on your fitness, education, skills, commitment, loyalty to the country and intellect. For Clandestine services you should hold a bachelor’s degree, must be the U.S. citizen, above 21 years and not married to a foreigner. You’ll undergo a rigorous formal training before getting a position.</p>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-2">
                                        <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                    </div>
                                    <div className="col-9 ml-2">
                                        <h3 className="gdlr-process-title">Step 4: Becoming a CIA agent</h3>
                                        <p className="text-justify">The official website of CIA has outlined the requirements where you get in touch with them and they will get back to you. You have to be physically and fit and strong nerved to stand the high levels of training for field or office work positions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row ml-0 mr-0">
                                <h2 id="cia-certification" className="sec-heading">CIA Certifications</h2>
                            </div>
                            <p>There are no regular certifications required by the CIA except basic conditions of having at least a graduation degree with no less than 3 CGPA.</p>
                            <div className="row ml-0 mr-0">
                                <h2 id="cia-career" className="sec-heading">CIA Career Advancement</h2>
                            </div>
                            <p>Once you are on board with the CIA, there are a lot of opportunities to grow in your area of expertise. You could work as NCS language officer, network engineer, political analyst, research psychologist, clinical psychologist, specialized skills set areas. Some positions are known to the public while several others are less known support positions working behind the scenes.</p>
                            <div className="row ml-0 mr-0">
                                <h2 id="should-you-become-cia-agent" className="sec-heading">Should You Become a CIA Agent?</h2>
                            </div>
                            <p>If you are a U.S. citizen between 21 to 35 years of age, not married to a foreign national, hold a bachelor’s degree or higher and you wish to be a CIA agent and want to know if you are eligible, have a look at the qualities and skills required to be a good CIA agent and you will have your answer.</p>
                            <ul className="content-listing pl-0" style={{ listStyleType: 'none' }}>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Strong interpersonal skills</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Leadership qualities</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Report writing</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Expertise in Microsoft Office</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Physical stamina</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Multitasking</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Comfortable using firearms and surveillance equipment</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Clean records</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Psychological strength</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Foreign language</li>
                            </ul>
                            <div className="row ml-0 mr-0">
                                <h2 id="going-away" className="sec-heading">Going Away Tip</h2>
                            </div>
                            <p>Working with the CIA is a highly responsive job for detecting, counteracting and monitoring foreign threats to the U.S. and the U.S. citizens abroad and so it puts very high demands on the agents. You must ask yourself if you really need this job. CIA agent careers are suitable to those willing to put their lives for providing security services while protecting their nation.</p>
                            <div className="row ml-0 mr-0">
                                <h2 id="sugges" className="sec-heading">Suggestions</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="pl-0 sug" style={{ listStyleType: 'none' }}>
                                        <li><a href="https://www.stepstobecomea.com/border-patrol-officer/">How to Become a Border Patrol in USA</a></li>
                                        <li><a href="https://www.stepstobecomea.com/criminologist/">How to Become a Criminologist in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/court-reporter/">How to Become a Court Reporter in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/cia-agent/">How to Become a CIA Agent in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/crime-scene-investigator/">How to Become a Crime Scene Investigator in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/cosmetologist/">How to Become a Cosmetologist in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/data-analyst/">How to Become a Data Analyst in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/fbi-agent/">How to Become a FBI Agent in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/financial-advisor/">How to Become a Financial Advisor in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/financial-analyst/">How to Become a Financial Analyst in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/parole-officer/">How to Become a Parole Officer in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/police-officer/">How to Become a Police Officer in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-california/">How to Become a Police Officer in California</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-florida/">How to Become a Police Officer in Florida</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-pennsylvania/">How to Become a Police Officer in Pennsylvania</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-new-york/">How to Become a Police Officer in New York</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-texas/">How to Become a Police Officer in Texas</a></li>
                                        <li><a href="https://stepstobecomea.com/private-investigator/">How to Become a Private Investigator in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/real-estate-agent/">How to Become a Real Estate Agent in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/realtor/">How to Become a Realtor in USA</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="pl-0 sug" style={{ listStyleType: 'none' }}>
                                        <li><a href="https://stepstobecomea.com/sheriff/">How to Become a Sheriff in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-california/">How to Become a Sheriff in California</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-florida/">How to Become a Sheriff in Florida</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-new-york/">How to Become a Sheriff in New York</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-pennsylvania/">How to Become a Sheriff in Pennsylvania</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-texas/">How to Become a Sheriff in Texas</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter/">How to Become a Firefighter in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-california/">How to Become a Firefighter in California</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-florida/">How to Become a Firefighter in Florida</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-new-york/">How to Become a Firefighter in New York</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-pennsylvania/">How to Become a Firefighter in Pennsylvania</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-texas/">How to Become a Firefighter in Texas</a></li>
                                        <li><a href="https://stepstobecomea.com/cpa/">How to Become a CPA in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/forensic-scientist/">How to Become a Border Patrol in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-california/">How to Become a Forensic Scientist in California</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-florida/">How to Become a Forensic Scientist in Florida</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-new-york/">How to Become a Forensic Scientist in New York</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-pennsylvania/">How to Become a Forensic Scientist in Pennsylvania</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-texas/">How to Become a Forensic Scientist in Texas</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </>
    )
}
