import React from 'react'
import './Steps.css';
import _cia from '../images/_cia.jpg';
import { Helmet } from "react-helmet";
import LoadingBar from 'react-top-loading-bar'

export default function Steps() {
    const d = new Date();
    let year = d.getFullYear().toString();

    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    return (
        <>
        <LoadingBar color='#001d3b' progress={100} height={5} />
            <Helmet>
                <title>Steps to become a CIA Agent - How to become a CIA Agent - {year}</title>
                <meta name='description' content='Introduction STEPS TO BECOME A CIA AGENT INTRODUCTION The CIA collects and delivers intelligence and sensitive data to the U.S. policy makers about the national security. It offers a variety of positions to scientists, computer experts, engineers, analysts, language experts, military specialists, and many more. The selection procedure is quite strict, highly professional and quite... Read More' />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div style={{ backgroundColor: "#E6ECF0" }}>
                <div className="container-fluid" style={{ backgroundColor: '#000', color: 'white' }}>
                    <div className="row justify-content-center text-center" style={{ padding: "2%", fontSize: "28px" }}>
                        STEPS TO BECOME A CIA AGENT
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#001d3b" data-header-text-color="light" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '765px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=001d3b&amp;header_text_color=light&amp;href=https%3A%2F%2Fwww.howtobecomeaciaagent.com%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <ul className="left-sec pl-0 sticky-top" style={{ listStyleType: 'none', width: '100%' }}>
                                <li><a href="#intro" onClick={(e) => removeDefault(e)}>Introduction</a></li>
                                <li><a href="#steps" onClick={(e) => removeDefault(e)}>STEPS TO BECOME A CIA AGENT</a></li>
                                <li><a href="#sugges" onClick={(e) => removeDefault(e)}>Suggestions</a></li>
                            </ul>
                        </div>
                        <div className="col-md-8">
                            <div className="row ml-0 mr-0">
                                <h2 id="intro" className="sec-heading">INTRODUCTION</h2>
                            </div>
                            <p>The CIA collects and delivers intelligence and sensitive data to the U.S. policy makers about the national security. It offers a variety of positions to scientists, computer experts, engineers, analysts, language experts, military specialists, and many more. The selection procedure is quite strict, highly professional and quite lengthy. In particular, the Clandestine Division needs agents to work undercover and to conduct covert operations, within and outside the U.S. against any potential threats to the U.S. national security. Becoming a CIA agent is a rewarding career but involves high risks and dealing with potentially dangerous situations that would require using weaponry. The CIA offers job security and job benefits to the selected candidates. If you are really interested to become a CIA agent, then carefully consider the following steps. These steps would guide you take a right decision in becoming a CIA agent. They contain in-detail information on relevant education, training, and exam requirements.</p>
                            <div className="container">
                                <div className="row" style={{ border: '1px solid #001d3b', borderRadius: '10px', backgroundColor: '#ccbd92' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}>STEPS TO BECOME A CIA AGENT</h5>
                                    </div>
                                    <div className="col-md-5 pt-2 pb-2 pr-2 pl-2">
                                        <img src={_cia} style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row ml-0 mr-0">
                                <h2 id="steps" className="sec-heading">STEPS TO BECOME A CIA AGENT</h2>
                            </div>
                            <div className="container-fluid p-0" id="steps">
                                <div className="row">
                                    <div className="col-2">
                                        <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                    </div>
                                    <div className="col-9 ml-2">
                                        <h3 className="gdlr-process-title">Step 1: Educational Requirements</h3>
                                        <p className="text-justify">Those aspiring to become operations officers, join the management or work as analysts in the CIA usually need to have at least a bachelor’s degree with a minimum of 3.0 GPA. Depending upon the field of services required the candidates might consider studying foreign studies, national security, international business, political science, finance or economics. Having competency in a foreign language, sensitivity to foreign culture and studying abroad is also important. Having a work experience in law enforcement makes you more competitive. The recruitment and clearance processes are often quite lengthy and they cover a comprehensive review of a candidate’s eligibility, honesty, no drugs or crime status, required skills competencies and a very strong loyalty to the United States. Top security clearance is a must. There are many physical, psychological and medical exams to go through. The aspiring candidates are also strictly tested on handling confidential information.</p>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-2">
                                        <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                    </div>
                                    <div className="col-9 ml-2">
                                        <h3 className="gdlr-process-title">Step 2: Working at the CIA</h3>
                                        <p className="text-justify">After their selection the CIA agents usually are placed in any of the specialized departments according to the candidates’ skill set and training. Those seeking positions within the CIA’s National Clandestine Service must complete a training program before working in the management and collection offices. Enrolling in mentoring programs, participating in seminars, attending conferences and pursuing higher education is also greatly valued by the CIA. The Directorate of Intelligence inducts political analysts, counter-terrorism analysts and other specialized analysts for different tasks and operations. According to cia.gov, (Aug. 2015), the annual salary range for CIA special agents was $74,872 - $136, 1771. As this is a tough field, the growth is lower than the average but employment opportunities would continue increasing. About 7% increase is expected in CIA agent jobs from 2016 to 2026.</p>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-2">
                                        <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                    </div>
                                    <div className="col-9 ml-2">
                                        <h3 className="gdlr-process-title">Step 3: Training Requirements</h3>
                                        <p className="text-justify">After selecting the right candidates, the CIA imparts specialized training and education to the field agents and the office professionals to support the assigned missions. They are required to complete the Criminal Investigation Training Program (CITP) through the Federal Law Enforcing Training Centre. The trainings involve dealing with the security and big data, intelligence gathering, analysis, drawing appropriate conclusions and working under pressure in field with clandestine teams and their operations.</p>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-2">
                                        <div className="gdlr-process-icon"><i className="fa fa-fire" style={{color: 'white', marginLeft: '-3px'}}></i></div>
                                    </div>
                                    <div className="col-9 ml-2">
                                        <h3 className="gdlr-process-title">Step 4: Licenses and Certifications</h3>
                                        <p className="text-justify">Since the CIA has her own covert systems and mechanisms of training and education, there are department based programs catering to the requirements of the specialized operations and intelligence gathering and analysis. The CIA agents must have a clear understanding of international issues and their operating environments. The CIA has four main organizations within the agency: The Directorate of Intelligence, The National Clandestine Service, The Directorate of Support, and the Directorate of Science and Technology. All these departments have their own internal assessments and employment processes which the candidates must pass before taking up any operations. Like other federal law enforcing jobs, the CIA jobs typically require the completion of a formal training program through the Federal Law Enforcing Agency (FLETC) as well as specialized training that includes the field work training and the continual on-job training.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row ml-0 mr-0">
                                <h2 id="sugges" className="sec-heading">Suggestions</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="pl-0 sug" style={{ listStyleType: 'none' }}>
                                        <li><a href="https://www.stepstobecomea.com/border-patrol-officer/">How to Become a Border Patrol in USA</a></li>
                                        <li><a href="https://www.stepstobecomea.com/criminologist/">How to Become a Criminologist in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/court-reporter/">How to Become a Court Reporter in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/cia-agent/">How to Become a CIA Agent in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/crime-scene-investigator/">How to Become a Crime Scene Investigator in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/cosmetologist/">How to Become a Cosmetologist in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/data-analyst/">How to Become a Data Analyst in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/fbi-agent/">How to Become a FBI Agent in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/financial-advisor/">How to Become a Financial Advisor in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/financial-analyst/">How to Become a Financial Analyst in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/parole-officer/">How to Become a Parole Officer in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/police-officer/">How to Become a Police Officer in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-california/">How to Become a Police Officer in California</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-florida/">How to Become a Police Officer in Florida</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-pennsylvania/">How to Become a Police Officer in Pennsylvania</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-new-york/">How to Become a Police Officer in New York</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-texas/">How to Become a Police Officer in Texas</a></li>
                                        <li><a href="https://stepstobecomea.com/private-investigator/">How to Become a Private Investigator in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/real-estate-agent/">How to Become a Real Estate Agent in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/realtor/">How to Become a Realtor in USA</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="pl-0 sug" style={{ listStyleType: 'none' }}>
                                        <li><a href="https://stepstobecomea.com/sheriff/">How to Become a Sheriff in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-california/">How to Become a Sheriff in California</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-florida/">How to Become a Sheriff in Florida</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-new-york/">How to Become a Sheriff in New York</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-pennsylvania/">How to Become a Sheriff in Pennsylvania</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-texas/">How to Become a Sheriff in Texas</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter/">How to Become a Firefighter in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-california/">How to Become a Firefighter in California</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-florida/">How to Become a Firefighter in Florida</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-new-york/">How to Become a Firefighter in New York</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-pennsylvania/">How to Become a Firefighter in Pennsylvania</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-texas/">How to Become a Firefighter in Texas</a></li>
                                        <li><a href="https://stepstobecomea.com/cpa/">How to Become a CPA in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/forensic-scientist/">How to Become a Border Patrol in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-california/">How to Become a Forensic Scientist in California</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-florida/">How to Become a Forensic Scientist in Florida</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-new-york/">How to Become a Forensic Scientist in New York</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-pennsylvania/">How to Become a Forensic Scientist in Pennsylvania</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-texas/">How to Become a Forensic Scientist in Texas</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        </>
    )
}
