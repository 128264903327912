import './App.css';
import Header from './Components/Header';
import Index from './Components/Index';
import States from './Components/States';
import Requirements from './Components/Requirements';
import Steps from './Components/Steps';
import Search from './Components/Search';
import Footer from './Components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path='*' element={<Index />} />
          <Route path="/states/" element={<States />} />
          <Route path="/requirements-to-become-a-cia-agent/" element={<Requirements />} />
          <Route path="/steps-to-become-a-cia-agent/" element={<Steps />} />
          <Route path="/search/" element={<Search />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
