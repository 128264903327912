import React from 'react'
import './Requirements.css';
import _cia from '../images/_cia.jpg';
import { Helmet } from "react-helmet";
import LoadingBar from 'react-top-loading-bar'

export default function Requirements() {
    const d = new Date();
    let year = d.getFullYear().toString();

    const removeDefault = (e) => {
        e && e.preventDefault();
        const href = e.currentTarget.hash.replace(/#/g,'');
        const elementToView = document.getElementById(href);
        elementToView.scrollIntoView();
    }

    return (
        <>
        <LoadingBar color='#001d3b' progress={100} height={5} />
            <Helmet>
                <title>Requirements to become a CIA Agent - How to become a CIA Agent - {year}</title>
                <meta name='description' content='Introduction Minimum requirements to become a CIA agent Education and Qualifications REQUIREMENTS OF THE APPLICATION PROCESS PASSING CIA AGENT SELECTION REQUIREMENTS PSYCHOLOGICAL PROFILE EVALUATION ESTABLISHING YOURSELF IN THE FIELD AS A CIA AGENT PERSONAL COMMITMENT AS A CIA AGENT APPLICATION PROCESSING TIME TRAINING INTRODUCTION The Central Intelligence Agency (CIA) is an independent, federally operating large... Read More' />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div style={{ backgroundColor: "#E6ECF0" }}>
                <div className="container-fluid" style={{ backgroundColor: '#000', color: 'white' }}>
                    <div className="row justify-content-center text-center" style={{ padding: "2%", fontSize: "28px" }}>
                        REQUIREMENTS TO BECOME A CIA AGENT
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="csn-widget" data-designation="both" data-hide-designation="1" data-affiliate="aUfIo" data-property="IOPjr" data-type="full-search" data-header-background="#001d3b" data-header-text-color="light" data-header="" data-tagline="" data-pixel="" style={{ width: '100%' }}>
                                <div className="csn-frame-container"><iframe className="csn-widget-iframe" scrolling="no" onload="this.style.display='block';" style={{ display: 'block', width: '100%', margin: '0px', padding: '0px', border: 'none', overflow: 'hidden', height: '765px' }} src="https://widget.careerschoolnow.org?type=full-search&amp;designation=both&amp;hide_designation=1&amp;affiliate=aUfIo&amp;property=IOPjr&amp;pixel=&amp;header=&amp;tagline=&amp;header_background=001d3b&amp;header_text_color=light&amp;href=https%3A%2F%2Fwww.howtobecomeaciaagent.com%2F" id="iFrameResizer0"></iframe></div></div>
                            <br />
                            <ul className="left-sec pl-0 sticky-top" style={{ listStyleType: 'none', width: '100%' }}>
                                <li><a href="#intro" onClick={(e) => removeDefault(e)}>Introduction</a></li>
                                <li><a href="#min-req" onClick={(e) => removeDefault(e)}>Minimum requirements to become a CIA agent</a></li>
                                <li><a href="#edu-qualification" onClick={(e) => removeDefault(e)}>Education and Qualifications</a></li>
                                <li><a href="#req-application-process" onClick={(e) => removeDefault(e)}>REQUIREMENTS OF THE APPLICATION PROCESS</a></li>
                                <li><a href="#selection-req" onClick={(e) => removeDefault(e)}>PASSING CIA AGENT SELECTION REQUIREMENTS</a></li>
                                <li><a href="#profile-evaluation" onClick={(e) => removeDefault(e)}>PSYCHOLOGICAL PROFILE EVALUATION</a></li>
                                <li><a href="#field" onClick={(e) => removeDefault(e)}>ESTABLISHING YOURSELF IN THE FIELD AS A CIA AGENT</a></li>
                                <li><a href="#personal-commitment" onClick={(e) => removeDefault(e)}>PERSONAL COMMITMENT AS A CIA AGENT</a></li>
                                <li><a href="#app-process" onClick={(e) => removeDefault(e)}>APPLICATION PROCESSING TIME</a></li>
                                <li><a href="#training" onClick={(e) => removeDefault(e)}>TRAINING</a></li>
                                <li><a href="#sugges" onClick={(e) => removeDefault(e)}>Suggestions</a></li>
                            </ul>
                        </div>
                        <div className="col-md-8">
                            <div className="row ml-0 mr-0">
                                <h2 id="intro" className="sec-heading">INTRODUCTION</h2>
                            </div>
                            <p>The Central Intelligence Agency (CIA) is an independent, federally operating large of body of multi discipline professionals working to preserve the U.S. nation’s interests,   policies, and national security issues worldwide. On the U.S. President’s directives, the CIA covertly collects and analyses foreign intelligence to design her policies on national security threats, foreign governments and industries, technological research, and terrorist cells operative within and outside global war zones. The CIA hires as her agents only the most qualified, immaculate and competent candidates with clean legal and social records. The hiring is through rigorous standards. The CIA employees could be scientists, computer experts, data analysts, economists, linguists, lawyers, mathematicians, accountants, medical experts and the like. According to cia.gov, (Aug. 2015), the annual salary range for CIA special agents was $74,872 – $136, 1771. As this is a tough field, the growth is lower than the average but employment opportunities would continue increasing. The CIA also hires lawyers as “Attorney (Honors and Lateral)” with a salary range of $68,712 – $136,771 annually. Likewise, the “Political Analyst” positions may get paid between $50,861 – 98,305. The salary figures can vary according to the location of service, particular skills set, education and experience. You may also refer to the GS scale for Federal Law Enforcement positions to determine the compensation. The CIA offers multiple careers in accordance with your education, skills, and experience. According to the BLS (2017), the number of jobs in CIA was 807,000, with a median pay of 62,960 per year.  So, you may get a slow but a rewarding career. If you have strong motivation to join the CIA, then continue reading the requirements to become a CIA agent.</p>
                            <div className="container">
                                <div className="row" style={{ border: '1px solid #001d3b', borderRadius: '10px', backgroundColor: '#ccbd92' }}>
                                    <div className="col-md-7 mt-auto mb-auto" style={{ padding: '2%' }}>
                                        <h5 style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}>REQUIREMENTS TO BECOME A CIA AGENT</h5>
                                    </div>
                                    <div className="col-md-5 pt-2 pb-2 pr-2 pl-2">
                                        <img src={_cia} style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="row ml-0 mr-0">
                                <h2 id="min-req" className="sec-heading">Minimum requirements to become a CIA Agent</h2>
                            </div>
                            <ul className="content-listing pl-0" style={{ listStyleType: 'none' }}>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Must be a U.S. citizen between 21 to 35 years of age, and not married to a foreign national</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Must have an undergraduate degree from an accredited institute with no less than 3.0 CGPA</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Must have at least three years of investigative experience</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Must pass an 18-month CIA training program through the Clandestine Service Trainee Program (CST) or the Headquarters-Based Training Program (HBT)</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Must be able to cope up with stress</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Must have excellent physical and psychological health with strong street sense and good intuition</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Must be willing to live and work overseas while having a command in a foreign language</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Must be willing to work in harsh conditions during covert operations within and outside the U.S.</li>
                                <li><i className="fa fa-check" aria-hidden="true"></i> Must have strong analytical, written and communication skills</li>
                            </ul>
                            <p>The above mentioned are the basic requirements to becoming a CIA agent. Depending upon the requirements of your specialized skills, the Agency will train you accordingly. These requirements are the basic ones but do not guarantee your hiring in this field which is reserved only for the most qualified and the high skilled candidates.</p>
                            <div className="row ml-0 mr-0">
                                <h2 id="edu-qualification" className="sec-heading">Education and Qualifications</h2>
                            </div>
                            <p>The CIA requires you to have at least a high school diploma or equivalent to formal graduate degree from an accredited institution with majors in any area of your choice. The CIA offers multiple careers in accordance with your education, skills, and experience. According to cia.gov, (Aug. 2015), the annual salary range for CIA special agents was $74,872 – $136, 1771, while for police officers involved in security protective services was in a range from $51,601 to $73,922. About 7% increase is expected in CIA agent jobs from 2016 to 2026.</p>
                            <p>	<strong>Tips:</strong></p>
                            <p>The CIA hires agents to safeguard the U.S. citizens’ interests, security and the U.S. Government’s policies in the global perspective against any potential threat. So only the best are selected to disseminate the job responsibilities.</p>
                            <p>The preferred areas of study include: business, international relations, computer sciences, criminal justice, investigative journalism, sciences, linguistics and others.</p>
                            <p>If you possess required education and skill set, and you get selected the Agency will provide you with all the rigorous trainings needed for your job description in the CIA.</p>
                            <p>These are the general guidelines to give you an idea on the requirements to become a CIA agent.</p>
                            <div className="row ml-0 mr-0">
                                <h2 id="req-application-process" className="sec-heading">REQUIREMENTS OF THE APPLICATION PROCESS</h2>
                            </div>
                            <p>You are required to answer all questions thoroughly and accurately with professional, ethical responsibility.</p>
                            <div className="row ml-0 mr-0">
                                <h2 id="selection-req" className="sec-heading">PASSING CIA AGENT SELECTION REQUIREMENTS</h2>
                            </div>
                            <p>The aspirant CIA applicants are required to pass their career path tracks in a wide range of selected exams, fields and intelligence operations. They must meet the minimum requirements for each field.</p>
                            <div className="row ml-0 mr-0">
                                <h2 id="profile-evaluation" className="sec-heading">PSYCHOLOGICAL PROFILE EVALUATION</h2>
                            </div>
                            <p>The CIA agents are passed through highest standards of psychological and professional procedure. You must have a clean criminal record. You would be required taking up several written tests, psychological abilities and interviews before getting yourself selected as a CIA agent.</p>
                            <div className="row ml-0 mr-0">
                                <h2 id="field" className="sec-heading">ESTABLISHING YOURSELF IN THE FIELD AS A CIA AGENT</h2>
                            </div>
                            <p>At your career start as a CIA agent at initial positions, it’ll be a bit harder for you to sustain in the field, but gradually you’ll get brighter opportunities if you continue excelling in on job trainings, education, and relevant experience growth.</p>
                            <div className="row ml-0 mr-0">
                                <h2 id="personal-commitment" className="sec-heading">PERSONAL COMMITMENT AS A CIA AGENT</h2>
                            </div>
                            <p>You are required to follow the best ethical and legal practices of U.S. loyalty and citizenship. You should be at the best of knowledge of your field and should have extensive experience in law enforcement duties. You should be dedicated to protect the U.S. citizens against any potential threats.</p>
                            <div className="row ml-0 mr-0">
                                <h2 id="app-process" className="sec-heading">APPLICATION PROCESSING TIME</h2>
                            </div>
                            <p>As the application process is a rigorous one and highly confidential, so you might expect getting it completed from anywhere around 90 days or even much longer.</p>
                            <div className="row ml-0 mr-0">
                                <h2 id="training" className="sec-heading">TRAINING</h2>
                            </div>
                            <p>Like other federal law enforcing jobs, the CIA jobs typically require the completion of a formal training program through the Federal Law Enforcing Agency (FLETC) as well as specialized training that includes the field work training and the continual on-job training. However, most of the CIA jobs descriptions are not open to the public because of the state security reasons. You are also expected to have a strong physical training, patience and stamina to handle job danger and stress. Typically, you should have at least three years of investigative experience before becoming eligible for CIA agent positions. You may stand better chances of being a CIA agent if you have a military training and experience.</p>
                            <div className="row ml-0 mr-0">
                                <h2 id="sugges" className="sec-heading">Suggestions</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <ul className="pl-0 sug" style={{ listStyleType: 'none' }}>
                                        <li><a href="https://www.stepstobecomea.com/border-patrol-officer/">How to Become a Border Patrol in USA</a></li>
                                        <li><a href="https://www.stepstobecomea.com/criminologist/">How to Become a Criminologist in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/court-reporter/">How to Become a Court Reporter in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/cia-agent/">How to Become a CIA Agent in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/crime-scene-investigator/">How to Become a Crime Scene Investigator in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/cosmetologist/">How to Become a Cosmetologist in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/data-analyst/">How to Become a Data Analyst in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/fbi-agent/">How to Become a FBI Agent in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/financial-advisor/">How to Become a Financial Advisor in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/financial-analyst/">How to Become a Financial Analyst in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/parole-officer/">How to Become a Parole Officer in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/police-officer/">How to Become a Police Officer in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-california/">How to Become a Police Officer in California</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-florida/">How to Become a Police Officer in Florida</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-pennsylvania/">How to Become a Police Officer in Pennsylvania</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-new-york/">How to Become a Police Officer in New York</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-police-officer-in-texas/">How to Become a Police Officer in Texas</a></li>
                                        <li><a href="https://stepstobecomea.com/private-investigator/">How to Become a Private Investigator in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/real-estate-agent/">How to Become a Real Estate Agent in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/realtor/">How to Become a Realtor in USA</a></li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="pl-0 sug" style={{ listStyleType: 'none' }}>
                                        <li><a href="https://stepstobecomea.com/sheriff/">How to Become a Sheriff in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-california/">How to Become a Sheriff in California</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-florida/">How to Become a Sheriff in Florida</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-new-york/">How to Become a Sheriff in New York</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-pennsylvania/">How to Become a Sheriff in Pennsylvania</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-sheriff-in-texas/">How to Become a Sheriff in Texas</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter/">How to Become a Firefighter in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-california/">How to Become a Firefighter in California</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-florida/">How to Become a Firefighter in Florida</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-new-york/">How to Become a Firefighter in New York</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-pennsylvania/">How to Become a Firefighter in Pennsylvania</a></li>
                                        <li><a href="https://stepstobecomea.com/firefighter-in-texas/">How to Become a Firefighter in Texas</a></li>
                                        <li><a href="https://stepstobecomea.com/cpa/">How to Become a CPA in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/forensic-scientist/">How to Become a Border Patrol in USA</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-california/">How to Become a Forensic Scientist in California</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-florida/">How to Become a Forensic Scientist in Florida</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-new-york/">How to Become a Forensic Scientist in New York</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-pennsylvania/">How to Become a Forensic Scientist in Pennsylvania</a></li>
                                        <li><a href="https://stepstobecomea.com/how-to-become-a-forensic-scientist-in-texas/">How to Become a Forensic Scientist in Texas</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>

        </>
    )
}
